import dynamic from "next/dynamic";
const App = dynamic(() => import("../components/App"));
import Head from "next/head";
import { useEffect, useState } from "react";
import axios from "axios";
import { Typography, Row, Col, Card, Divider, Button, Spin } from "antd";
import NextImage from "next/image";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import ThumborJS from "thumborjs";

const imageUrl = process.env.NEXT_PUBLIC_IMAGE_URL;
const thumborKey = process.env.NEXT_PUBLIC_THUMBOR_KEY;
const thumborUrl = process.env.NEXT_PUBLIC_THUMBOR_URL;

import { Api } from "../networks/Api";
import { useRouter } from "next/router";

// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

const { Title } = Typography;

// Component
const ProductSlide = dynamic(() => import("../components/ProductSlideScroll"), {
  ssr: false,
});

const SocialSlideScroll = dynamic(
  () => import("../components/SocialSlideScroll"),
  { ssr: false }
);
const Brand = dynamic(() => import("../components/Home/Brand"), { ssr: false });

const page = 1;
const size = 20;

export async function getServerSideProps(ctx) {
  let brandData = [],
    categoryData = [],
    subCategoryData = [],
    feedBrand = [],
    bannerData = [],
    bannerMData = [];

  try {
    // Feed brand
    const url_brand = `${Api.getFeedBrandList}`;
    const response_brand = await axios.get(url_brand, Api.Header());
    if (response_brand?.status == 200) {
      // แปลงโครงสร้างข้อมูล
      brandData = response_brand?.data?.data?.reduce((acc, item) => {
        acc[item.number] = item.name;
        return acc;
      }, {});
    }

    // Feed group category
    const url_group_cate = `${Api.getFeedGroupCategory}`;
    const response_group_cate = await axios.get(url_group_cate, Api.Header());
    if (response_group_cate?.status == 200) {
      let group_category = response_group_cate?.data?.data;
      categoryData = group_category.reduce((acc, item) => {
        acc[item.number] = item.name_en;
        return acc;
      }, {});
    }

    // Feed sub category
    const url_sub_cate = `${Api.getFeedSubCategory}`;
    const response_sub_cate = await axios.get(url_sub_cate, Api.Header());
    if (response_sub_cate?.status == 200) {
      let sub_category = response_sub_cate?.data?.data;
      subCategoryData = sub_category.reduce((acc, item) => {
        acc[item.number] = item.name;
        return acc;
      }, {});
    }

    // Feed brand home
    const url_brand_home = `${Api.getFeedBrandHome}?page=1&size=16`;
    const response_brand_home = await axios.get(url_brand_home, Api.Header());
    if (response_brand_home?.status == 200) {
      feedBrand = response_brand_home?.data?.data;
    }

    // Feed banner top
    const url_banner_top = `${Api.getFeedBannerList}/top`;
    const response_banner_top = await axios.get(url_banner_top, Api.Header());
    if (response_banner_top?.status == 200) {
      bannerData = response_banner_top?.data?.data;
    }
  } catch (e) {
    console.log(e);
  }

  return {
    props: {
      ...(await serverSideTranslations(ctx?.locale, ["common"])),
      token: ctx?.req?.cookies?.token || null,
      brandData,
      categoryData,
      subCategoryData,
      feedBrand,
      bannerData,
    },
  };
}

const Home = ({
  token,
  categoryMenu,
  hasSale,
  hasLive,
  dataSeo,
  dataReferrer,
  brandData,
  categoryData,
  subCategoryData,
  feedBrand,
  bannerData,
}) => {
  const router = useRouter();
  let queryParam = router?.query?.aid ? `aid=${router?.query?.aid}` : ``;
  queryParam = router?.query?.pid
    ? `${queryParam}&pid=${router?.query?.pid}`
    : queryParam;

  queryParam = router?.query?.pf
    ? `${queryParam}pf=${router?.query?.pf}`
    : queryParam;

  if (router?.query?.utm_source) {
    queryParam = queryParam
      ? `${queryParam}&utm_source=${router?.query?.utm_source}`
      : `?utm_source=${router?.query?.utm_source}`;
  }

  const { t } = useTranslation("common");

  const [loading, setLoading] = useState(true);
  const [feedProductRec, setFeedProductRec] = useState([]);
  const [feedProductNew, setFeedProductNew] = useState([]);
  const [feedProductBest, setFeedProductBest] = useState([]);
  const [feedBannerTop, setFeedBannerTop] = useState([]);
  const [feedBannerMiddle, setFeedBannerMiddle] = useState([]);
  const [firstContent, setFirstContent] = useState([]);
  const [secondContent, setSecondContent] = useState([]);
  const [thirdContent, setThirdContent] = useState([]);
  const [socialContent, setSocialContent] = useState([]);
  const [discountAll, setDiscountAll] = useState({});
  const ratioBanner = 2592 / 820;
  const ratioBannerM = 640 / 478;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${Api.webpromotionAll}`;
        const res = await axios.get(url, Api.Header());
        if (res?.status == 200) {
          setDiscountAll(res?.data?.data);
        }
      } catch (e) {
        // console.log("fetchData >> webpromotionAll error :", e);
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url_rec = `${Api.getFeedProductList}?page=${page}&size=${size}&type=recommend`;
        const response_rec = await axios.get(url_rec, Api.Header());
        if (response_rec?.status == 200) {
          setFeedProductRec(response_rec?.data?.data);
        }
      } catch (e) {}
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Feed product new
        const url_new = `${Api.getFeedProductList}?page=${page}&size=${size}&type=new`;
        const response_new = await axios.get(url_new, Api.Header());
        if (response_new?.status == 200) {
          setFeedProductNew(response_new?.data?.data);
        }
      } catch (e) {}
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Feed product best
        const url_best = `${Api.getFeedProductList}?page=${page}&size=${size}&type=best`;
        const response_best = await axios.get(url_best, Api.Header());
        if (response_best?.status == 200) {
          setFeedProductBest(response_best?.data?.data);
        }
      } catch (e) {}
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Feed banner middle
        const url_banner_middle = `${Api.getFeedBannerList}/middle`;
        const response_banner_middle = await axios.get(
          url_banner_middle,
          Api.Header()
        );
        if (response_banner_middle?.status == 200) {
          setFeedBannerMiddle(response_banner_middle?.data?.data);
        }
      } catch (e) {}
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Feed firstcontent
        const url_firstcontent = `${Api.contentHomepage}?type=firstcontent`;
        const response_firstcontent = await axios.get(
          url_firstcontent,
          Api.Header()
        );
        if (response_firstcontent?.status == 200) {
          setFirstContent(response_firstcontent?.data?.data);
        }
      } catch (e) {}
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Feed secondcontent
        const url_secondcontent = `${Api.contentHomepage}?type=secondcontent`;
        const response_secondcontent = await axios.get(
          url_secondcontent,
          Api.Header()
        );
        if (
          response_secondcontent?.status == 200 &&
          response_secondcontent?.data?.data?.length > 0
        ) {
          setSecondContent(response_secondcontent?.data?.data[0]);
        }
      } catch (e) {}
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Feed thirdcontent
        const url_thirdcontent = `${Api.contentHomepage}?type=thirdcontent`;
        const response_thirdcontent = await axios.get(
          url_thirdcontent,
          Api.Header()
        );
        if (response_thirdcontent?.status == 200) {
          setThirdContent(response_thirdcontent?.data?.data);
        }
      } catch (e) {}
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Feed socialcontent
        const url_socialcontent = `${Api.contentHomepage}?type=socialcontent`;
        const response_socialcontent = await axios.get(
          url_socialcontent,
          Api.Header()
        );
        if (response_socialcontent?.status == 200) {
          setSocialContent(response_socialcontent?.data?.data);
        }
      } catch (e) {}
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, []);

  const myLoader = ({ src, width }) => {
    if (src.split("?")?.length > 1) {
      return `${src}`;
    } else {
      const tugen = new ThumborJS(thumborUrl, thumborKey);
      const url = tugen.imgpath(src).resize(width, 0).genurl();

      return url;
    }
  };

  const myLoaderBanner = ({ src, width }) => {
    let result_width = width;
    const height = Math.ceil(result_width / ratioBanner);
    const tugen = new ThumborJS(thumborUrl, thumborKey);
    const url = tugen.imgpath(src).resize(result_width, height).genurl();

    return url;
  };

  const myLoaderBannerM = ({ src, width }) => {
    let result_width = width;
    if (width >= 575) {
      result_width = 575;
    }
    const height = Math.ceil(result_width / ratioBannerM);
    const tugen = new ThumborJS(thumborUrl, thumborKey);
    const url = tugen.imgpath(src).resize(result_width, height).genurl();

    return url;
  };

  const image_path =
    bannerData?.length >= 1
      ? bannerData[0]?.website_image_path?.replace(/\/uploads/g, "")
      : "";
  const firstBanner = myLoaderBanner({
    src: `${imageUrl}${image_path}`,
    width: 1920,
  });

  const image_path_m =
    bannerData?.length >= 1
      ? bannerData[0]?.mobile_image_path?.replace(/\/uploads/g, "")
      : "";
  const firstBannerM = myLoaderBannerM({
    src: `${imageUrl}${image_path_m}`,
    width: 575,
  });

  if (loading) {
    return (
      <div className="loading-screen">
        <Spin size="large" tip="Loading..." />
      </div>
    );
  } else
    return (
      <App
        token={token}
        categoryMenu={categoryMenu}
        hasSale={hasSale}
        hasLive={hasLive}
        dataSeo={dataSeo}
        dataReferrer={dataReferrer}
      >
        {process.env.NEXT_PUBLIC_APP_ENV == "production" ? (
          <Head>
            <meta
              name="google-site-verification"
              content="dloZRLwFVKf-XdY_u3X1JJ4CgYf6tReRWP3Q9wmt3qk"
            />
            {bannerData?.length >= 1 ? (
              <>
                <link rel="preload" as="image" href={firstBanner} />
                <link rel="preload" as="image" href={firstBannerM} />
              </>
            ) : (
              <></>
            )}
          </Head>
        ) : (
          <></>
        )}
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={20}>
            {/* BANNER TOP */}
            <Row>
              <Col xs={0} sm={24} md={24} lg={24} xl={24}>
                <div className="mySwiper-banner">
                  <Swiper
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                      delay: 20000,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                  >
                    {bannerData?.map((v, index) => {
                      const url =
                        v?.event_type == "live"
                          ? `/live`
                          : v?.url
                          ? v?.url
                          : ``;
                      const image_path = v?.website_image_path?.replace(
                        /\/uploads/g,
                        ""
                      );
                      const imageProduct = v?.website_image_path
                        ? `${imageUrl}${image_path}`
                        : v?.website_image_path
                        ? v?.website_image_path
                        : `${process.env.NEXT_PUBLIC_FRONTEND_URL}/static/images/thumbnail.jpg`;
                      return (
                        <SwiperSlide
                          key={`feedBannerTop-${v?.id}`}
                          className="text-center"
                          onClick={() => {
                            if (url) {
                              router.push(
                                `${`${url}${
                                  queryParam ? `?${queryParam}` : ``
                                }`}`
                              );
                            }
                          }}
                        >
                          <NextImage
                            loader={myLoaderBanner}
                            src={imageProduct}
                            alt="Image"
                            layout="responsive"
                            width={640}
                            height={203}
                            priority={index == 0 ? true : false}
                            loading={index > 0 ? "lazy" : undefined}
                            placeholder="blur"
                            blurDataURL={`${process.env.NEXT_PUBLIC_FRONTEND_URL}/static/images/thumbnail.jpg`}
                            className={
                              url ? "banner-img-pointer" : "banner-img"
                            }
                            formats={["image/webp"]} // ใช้ฟอร์แมตรูปภาพ WebP
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </Col>
              <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                <div className="mySwiper-banner">
                  <Swiper
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                      delay: 20000,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                  >
                    {bannerData?.map((v, index) => {
                      const url =
                        v?.event_type == "live"
                          ? `/live`
                          : v?.url
                          ? v?.url
                          : ``;
                      const image_path = v?.mobile_image_path?.replace(
                        /\/uploads/g,
                        ""
                      );
                      const imageProduct = v?.mobile_image_path
                        ? `${imageUrl}${image_path}`
                        : v?.mobile_image_path
                        ? v?.mobile_image_path
                        : `${process.env.NEXT_PUBLIC_FRONTEND_URL}/static/images/thumbnail.jpg`;
                      return (
                        <SwiperSlide
                          key={`feedBannerTop-mobile-${v?.id}`}
                          className="text-center"
                          onClick={() => {
                            if (url) {
                              router.push(
                                `${`${url}${
                                  queryParam ? `?${queryParam}` : ``
                                }`}`
                              );
                            }
                          }}
                        >
                          <NextImage
                            loader={myLoaderBannerM}
                            src={imageProduct}
                            alt="Image"
                            layout="responsive"
                            width={320}
                            height={239}
                            priority={index == 0 ? true : false}
                            loading={index > 0 ? "lazy" : undefined}
                            className={url ? "banner-img" : ""}
                            quality={75} // ค่าเริ่มต้นคือ 75
                            formats={["image/webp"]} // ใช้ฟอร์แมตรูปภาพ WebP
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </Col>
            </Row>
            {/* END BANNER TOP */}
          </Col>
        </Row>

        <Row justify="center" className="section-product">
          <Col xs={24} sm={23} md={23} lg={23} xl={20}>
            <ProductSlide
              title={`Best Seller`}
              url={`best${queryParam ? `?${queryParam}` : ``}`}
              t={t}
              products={feedProductBest}
              categoryData={categoryData}
              subCategoryData={subCategoryData}
              discountAll={discountAll}
              brandData={brandData}
            />
          </Col>

          <Col xs={24} sm={23} md={23} lg={23} xl={20}>
            <ProductSlide
              title={`New Arrivals`}
              url={`new${queryParam ? `?${queryParam}` : ``}`}
              t={t}
              products={feedProductNew}
              categoryData={categoryData}
              subCategoryData={subCategoryData}
              discountAll={discountAll}
              brandData={brandData}
            />
          </Col>

          <Col xs={24} sm={23} md={23} lg={23} xl={20}>
            <ProductSlide
              title={`Recommended Products`}
              url={`recommend${queryParam ? `?${queryParam}` : ``}`}
              t={t}
              products={feedProductRec}
              categoryData={categoryData}
              subCategoryData={subCategoryData}
              discountAll={discountAll}
              brandData={brandData}
            />
          </Col>
        </Row>

        {/* BANNER MIDDLE */}
        <Row className="mt-container" justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={20}>
            <Row>
              <Col xs={0} sm={24} md={24} lg={24} xl={24}>
                <div className="mySwiper-banner">
                  <Swiper
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                      delay: 20000,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                  >
                    {feedBannerMiddle?.map((v) => {
                      const url =
                        v?.event_type == "live"
                          ? `/live`
                          : v?.url
                          ? v?.url
                          : ``;
                      const image_path = v?.website_image_path?.replace(
                        /\/uploads/g,
                        ""
                      );
                      const imageProduct = v?.website_image_path
                        ? `${imageUrl}${image_path}`
                        : v?.website_image_path
                        ? v?.website_image_path
                        : `${process.env.NEXT_PUBLIC_FRONTEND_URL}/static/images/thumbnail.jpg`;
                      return (
                        <SwiperSlide
                          key={`feedBannerMiddle-${v?.id}`}
                          className="text-center"
                          onClick={() => {
                            if (url) {
                              router.push(
                                `${`${url}${
                                  queryParam ? `?${queryParam}` : ``
                                }`}`
                              );
                            }
                          }}
                        >
                          <NextImage
                            loader={myLoaderBanner}
                            src={imageProduct}
                            alt="Image"
                            layout="responsive"
                            width={10}
                            height={3}
                            loading="lazy"
                            className={url ? "banner-img" : ""}
                            quality={75} // ค่าเริ่มต้นคือ 75
                            formats={["image/webp"]} // ใช้ฟอร์แมตรูปภาพ WebP
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </Col>
              <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                <div className="mySwiper-banner">
                  <Swiper
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                      delay: 20000,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                  >
                    {feedBannerMiddle?.map((v) => {
                      const url =
                        v?.event_type == "live"
                          ? `/live`
                          : v?.url
                          ? v?.url
                          : ``;
                      const image_path = v?.mobile_image_path?.replace(
                        /\/uploads/g,
                        ""
                      );
                      const imageProduct = v?.mobile_image_path
                        ? `${imageUrl}${image_path}`
                        : v?.mobile_image_path
                        ? v?.mobile_image_path
                        : `${process.env.NEXT_PUBLIC_FRONTEND_URL}/static/images/thumbnail.jpg`;
                      return (
                        <SwiperSlide
                          key={`feedBannerMiddle-mobile-${v?.id}`}
                          className="text-center"
                          onClick={() => {
                            if (url) {
                              router.push(
                                `${`${url}${
                                  queryParam ? `?${queryParam}` : ``
                                }`}`
                              );
                            }
                          }}
                        >
                          <NextImage
                            loader={myLoaderBannerM}
                            src={imageProduct}
                            alt="Image"
                            layout="responsive"
                            width={5}
                            height={4}
                            loading="lazy"
                            className={url ? "banner-img" : ""}
                            quality={75} // ค่าเริ่มต้นคือ 75
                            formats={["image/webp"]} // ใช้ฟอร์แมตรูปภาพ WebP
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* END BANNER MIDDLE */}
        {firstContent?.length != 0 && (
          <Row className="mt-container" justify="center">
            <Col xs={24} sm={23} md={23} lg={23} xl={20}>
              <Row justify="center">
                {firstContent?.map((content, index) => {
                  const image_path = content?.image_path?.replace(
                    /\/uploads/g,
                    ""
                  );
                  const imageProduct = content?.image_path
                    ? `${imageUrl}${image_path}`
                    : content?.image_path
                    ? content?.image_path
                    : `${process.env.NEXT_PUBLIC_FRONTEND_URL}/static/images/thumbnail.jpg`;
                  return (
                    <Col
                      key={`firstContent-${index}`}
                      xs={23}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={8}
                      className="mb-container p"
                    >
                      <Link
                        href={`${content?.url}${
                          queryParam ? `?${queryParam}` : ``
                        }`}
                      >
                        <a>
                          <Card
                            hoverable
                            className="card-firstContent"
                            cover={
                              <div className="w-100">
                                <NextImage
                                  loader={myLoader}
                                  src={imageProduct}
                                  alt={content?.title}
                                  layout="responsive"
                                  width={1}
                                  height={1}
                                  loading="lazy"
                                  blurDataURL={`${process.env.NEXT_PUBLIC_FRONTEND_URL}/static/images/thumbnail.jpg`}
                                />
                              </div>
                            }
                          >
                            <p className="h2 title">{`${content?.title}`}</p>
                            <p className="h5 desc">Shop Now</p>
                          </Card>
                        </a>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        )}
        <Divider />
        {secondContent?.length != 0 && (
          <Row justify="center">
            <Col xs={24} sm={23} md={23} lg={23} xl={20}>
              <Row justify="center">
                <Col
                  xs={23}
                  sm={23}
                  md={12}
                  lg={12}
                  xl={12}
                  className="text-center p mb-container v-center"
                >
                  <p className="h2 title-content-sec">{`${secondContent?.title}`}</p>
                  <p className="h5 title-sub-content-sec">
                    {`${secondContent?.description}`}
                  </p>
                  <Link
                    href={`${secondContent?.url}${
                      queryParam ? `?${queryParam}` : ``
                    }`}
                  >
                    <a>
                      <Button className="mt-container">Shop Now</Button>
                    </a>
                  </Link>
                </Col>
                <Col
                  xs={23}
                  sm={23}
                  md={12}
                  lg={12}
                  xl={12}
                  className="mb-container"
                >
                  <NextImage
                    loader={myLoader}
                    src={`${imageUrl}${secondContent?.image_path?.replace(
                      /\/uploads/g,
                      ""
                    )}`}
                    alt={secondContent?.title}
                    layout="responsive"
                    width={1}
                    height={1}
                    blurDataURL={`${process.env.NEXT_PUBLIC_FRONTEND_URL}/static/images/thumbnail.jpg`}
                    loading="lazy"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Divider />
        {thirdContent?.length > 0 && (
          <Row justify="center">
            <Col xs={23} sm={23} md={23} lg={23} xl={20}>
              <Row justify="center">
                {thirdContent?.map((content, index) => {
                  return (
                    <Col
                      key={`thirdContent-${index}`}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="mb-container p"
                    >
                      <Link
                        href={`${content?.url}${
                          queryParam ? `?${queryParam}` : ``
                        }`}
                      >
                        <a>
                          <Card
                            hoverable
                            className="card-third"
                            cover={
                              <div>
                                <NextImage
                                  loader={myLoader}
                                  src={`${imageUrl}${content?.image_path.replace(
                                    /\/uploads/g,
                                    ""
                                  )}`}
                                  alt={content?.title}
                                  layout="responsive"
                                  width={600}
                                  height={899}
                                  blurDataURL={`${process.env.NEXT_PUBLIC_FRONTEND_URL}/static/images/thumbnail.jpg`}
                                  loading="lazy"
                                />
                              </div>
                            }
                          >
                            <p className="h2 title">{`${content?.title}`}</p>
                            <p className="h5 desc">Shop Now</p>
                          </Card>
                        </a>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        )}
        <Divider />
        {feedBrand?.length > 0 && <Brand brands={feedBrand} />}
        {socialContent?.length > 0 && (
          <Row justify="center" className=" bg-gray">
            <Col
              xs={24}
              sm={23}
              md={23}
              lg={23}
              xl={20}
              className="m-container"
            >
              <SocialSlideScroll contents={socialContent} />
            </Col>
          </Row>
        )}
      </App>
    );

  return <div />;
};

export default Home;
